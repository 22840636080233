.carousel {
  margin-bottom: 4rem;
}

.rocketprof-background {
  background-color: rgb(233, 232, 232);
}

.carousel-item {
  height: 32rem;
}

.carousel-inner {
  background-color: white; /* Change the background color to grey */
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: white;
  background-color: rgba(var(--bs-primary-rgb)) !important;
}
